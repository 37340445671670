<!--成本分析详情 xwh 20210910-->
<template>
    <div class="detail">
        <div class="tab_box">
                <div class="tab-item" :class="{'selected':activeName == 'tj'}" @click="titleTab('tj')">同建设方项目 {{validCount}}</div>
                <div class="tab-item" :class="{'selected':activeName == 'btj'}" @click="titleTab('btj')">不同建设方项目 {{invalidCount}}</div>
        </div>
        <effective v-show="activeName == 'tj'" :validTableData="validTableData"></effective>
        <invalid v-show="activeName == 'btj'" :invalidTableData="invalidTableData" ></invalid>
    </div>
</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
import effective from '@/views/businessCost/effective.vue'
import invalid from '@/views/businessCost/invalid.vue'
export default {
    components: {
        effective:effective,
        invalid:invalid,
    },
    props: {
        'proInfo': {},
    },
    data() {
        return {
            activeName: 'tj',
            validCount: 0,
            invalidCount: 0,
            validTableData: [],
            invalidTableData: [],
            company: '',
            executor: '',
            creditCode: '',
        }
    },
    created() {
    },
    mounted() {
        this.company = this.proInfo.company;
        this.executor = this.proInfo.executor;
        this.creditCode = this.proInfo.creditCode;
        this.getDetailsData('same');
        this.getDetailsData('different');
    },
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'businessCost',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        getDetailsData(model) {
            this.axios({
                method: "post", url: '/dms/ListExecutorInfo',
                 
                data: {
                    projectId: getStore('thisProject').id,  //项目id
                    company: this.company,
                    creditCode: this.creditCode,
                    executor: this.executor,
                    model: model
                },
            }).then((res) => {
                if(res.data.data.length>0){
                    if(model=='same'){
                        this.validTableData = res.data.data
                        this.validCount = res.data.data.length;
                    }else{
                        this.invalidTableData = res.data.data;
                        this.invalidCount = res.data.data.length;
                    }
                }
                
            }).catch((error) => {
                console.log(error)
            })
        },
        titleTab(title){
            this.activeName = title;
            // this.pageNum = 1;
            // this.reset();
            if(this.activeName=='tj'){
                this.getReport(2,'sameProject','sameBtn');
            }else{
                this.getReport(2,'differentProject','differentBtn');
            }
        },
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 4px;
}

.detail{
    height: 590px;
    border-top: 1px solid #DCDEE0;
    padding: 16px;
}
.tab_box{
    width: 100%;
    height: 60px;
    .tab-item{
        float: left;
        margin: 24px;
        cursor: pointer;
        font-size: 16px;
    }
    
    .selected {
        color: #185BCE;
    }
}
</style>