<template>
    <!--不同建设-->
    <div class="base-container" >
        <div class="input_box">
            <i class="el-icon-setting set" @click="getMoreCol"></i>
            <div class="col_more" v-show="isColMore">
                    <p class="title">编辑列</p>
                    <div class="reset" @click="reset">重置</div>
                <el-table
                    ref="multipleTable"
                    :data="tagData"
                    :show-header="false"
                    @selection-change="handleSelectionChangeTag">
                    <el-table-column
                    type="selection"
                    width="55">
                    </el-table-column>
                    <el-table-column>
                    <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>
                
                </el-table>
            </div>
        </div>
        <div class="main_box">
            
            <div class="table-box">
                <el-table ref="table" style="width: 100%" :data="tableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}" @sort-change="sortChange"  @selection-change="handleSelectionChange">
                   <el-table-column type="index" label="序号" align="center" width="70"></el-table-column>
                    <el-table-column sortable='custom' prop="projectName" label="项目名称" align="left" width="300" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column sortable='custom' prop="company" label="建设单位" align="left" width="300" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column sortable='custom' prop="contractCostString" label="合同金额（万元）" width="170" align="right"></el-table-column>
                    <el-table-column prop="buildingAreaTotal" align="right" label="建设规模(平方米）" :show-overflow-tooltip="true" width="190"></el-table-column>
                    <el-table-column sortable='custom' prop="cost" label="单价（元/m²）" align="right"></el-table-column>
                    <el-table-column sortable='custom' prop="contractType" label="合同类型" align="left" :show-overflow-tooltip="true" width="140"></el-table-column>

                    <el-table-column prop="source" label="数据来源" align="left" :show-overflow-tooltip="true" v-if="idArr.includes('sjly')" width="120"></el-table-column>
                    <el-table-column prop="contractContent" label="合同内容" align="left" :show-overflow-tooltip="true" v-if="idArr.includes('htrr')" width="120"></el-table-column>
                    <el-table-column sortable='custom' prop="region" label="区域" align="left" :show-overflow-tooltip="true" v-if="idArr.includes('qy')" width="160"></el-table-column>
                    <!-- <el-table-column prop="salePrice" label="总可售面积" :show-overflow-tooltip="true" v-if="idArr.includes('zksmj')" width="100"></el-table-column> -->
                    <el-table-column sortable='custom' prop="contractDate" align="right" label="合同日期" :show-overflow-tooltip="true" v-if="idArr.includes('htrq')" width="160"></el-table-column>
                   
                </el-table>
                <div class="page-box">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        background
                        layout="prev,pager,next,total,jumper"
                        :total=total
                        :current-page=pageNum>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            // console.log(e.target)
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};

export default {
    components: {
    },
    props: {
        invalidTableData: [],
    },
    data() {
        return {
            multipleTable:[],
            isColMore: false,
            idArr: [],
            landIds: [],
            tagData: [
                {
                    name:'数据来源',
                    id:'sjly'
                },
                {
                    name:'合同内容',
                    id:'htnr'
                },
                {
                    name:'区域',
                    id:'qy'
                },
                {
                    name:'合同日期',
                    id:'htrq'
                },
               
            ],
            tableData: [],
            pageNum: 1,
            total: 0,
        }
    },
    created() {
        
    },
    watch:{
        invalidTableData(){
            let arr =[].concat(this.invalidTableData);
            for(let i = 0;i<arr.length;i++){
                arr[i].contractCostString=arr[i].contractCostString-0;
                arr[i].cost=arr[i].cost-0;
            }
            this.tableData = arr.splice((this.pageNum-1)*10,10);
            this.total = this.invalidTableData.length;
        }
       
    },
    mounted() {
        let arr =[].concat(this.invalidTableData);
        this.tableData = arr.splice((this.pageNum-1)*10,10);
        this.total = this.invalidTableData.length;
    },
    directives: {clickoutside},
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'businessCost',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //展示更多筛选条件
        handleSelectionChange(val){
            let arr = [];
            val.forEach(function(e){  
                arr.push(e.urlAbbr)
            });
            this.landIds = arr;
        },
        handleSelectionChangeTag(val){
            this.getReport(2,'differentProject','colEdit');
            let arr = [];
            val.forEach(function(e){  
                arr.push(e.id)
            });
            this.idArr = arr;

        },
        getMoreCol(){
            this.isColMore = !this.isColMore;
        },
        reset(){
            this.getReport(2,'differentProject','colReset');
            this.$refs.multipleTable.clearSelection();
        },
        changeType(){
            this.$emit("listenToChildEvent",this.landIds)
        },
         //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'differentProject',val);
            this.pageNum = val;
            let arr =[].concat(this.invalidTableData);
            for(let i = 0;i<arr.length;i++){
                arr[i].contractCostString=arr[i].contractCostString-0;
                arr[i].cost=arr[i].cost-0;
            }
            this.tableData = arr.splice((this.pageNum-1)*10,10);
            this.total = this.invalidTableData.length;
        },
        sortChange({ prop, order }) {
            this.getReport(2,'differentProject',prop+'Sort');
            this.invalidTableData.sort(this.compare(prop,order));
            this.pageNum = 1;
        },
        compare (propertyName, sort) {
            return function (obj1, obj2) {
                var value1 = obj1[propertyName]
                var value2 = obj2[propertyName]
                if (typeof value1 === 'string' && typeof value2 === 'string') {
                    const res = value1.localeCompare(value2, 'zh')
                    return sort === 'ascending' ? res : -res
                } else {
                    if (value1 <= value2) {
                        return sort === 'ascending' ? -1 : 1
                    } else if (value1 > value2) {
                        return sort === 'ascending' ? 1 : -1
                    }
                }
            }
        }


      
    }
}
</script>

<style scoped lang="less">
.base-container{
    overflow: inherit;
    height: 540px !important;
}
.main_box{
    overflow-y: scroll;
    height: 520px !important;
}
.table-box{
    display: block;
}
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 4px;
}

.set{
    position: absolute;
    right: 6px;
    top: -28px;
    font-size: 16px;
    color: #185BCE;
    cursor: pointer;
}
.input_box{
    width: 100%;
    height: 1px;
    margin-bottom: 12px;
    position: relative;
    >p{
        float: left;
        line-height: 32px;
    }
    .el-input{
        float: left;
        width: 170px;
        margin-right: 10px;
    }
    .el-select{
        float: left;
        height: 32px;
        width: 160px;
        margin-right: 6px;
    }
    .el-select{
        height: 32px;
        margin-bottom: 12px;
        /deep/.el-input__icon{
            line-height: 32px !important;
        }
    }
    /deep/.el-input__inner{
        width: 160px;
        height: 32px !important;
        line-height: 32px !important;
    }
    .el-button{
        float: left;
        margin-left: 12px;
    }
    .push_chart{
        margin-left: 0 !important;
    }
    .more_search{
        color: #185BCE;
        cursor: pointer;
    }
    .right{
        float: right;
        height: 100%;
        line-height: 32px;
        color: #185BCE;
        >p{
            float: left;
            margin-right: 16px;
            cursor: pointer;
        }
        >i{
            cursor: pointer;
        }
    }
    .col_more{
        width: 214px;
        height: 252px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
        position: absolute;
        top: 0;
        right: 5px;
        box-sizing: border-box;
        padding: 14px 0;
        z-index: 99;
        overflow-y: scroll;
        .reset{
            position: absolute;
            top: 17px;
            right: 17px;
            width: 28px;
            height: 20px;
            font-size: 14px;
            color: #185BCE;
            cursor: pointer;
        }
        /deep/.el-table td,.el-table th.is-leaf,.el-table--border,.el-table--group {
            border: none;
            cursor: pointer;
        }
        .el-table::before {
            height: 0;
        }
        .title{
            font-size: 16px;
            color: #323233;
            margin-bottom: 26px;
            padding-left: 11px;
            font-weight: 600;
        }
        
    }
}

</style>