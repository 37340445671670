<!--成本分析 lh 20211108-->
<template>
    <div class="base-container">
        <el-main>
            <div>
                <div class="top_title">成本分析</div>
                <div class="input_box">
                    <p>施工企业：</p>
                    <el-input class="search-content" prefix-icon="el-icon-search" size="small" v-model="executor" placeholder="点击输入企业名称" ></el-input>
                    <p>合同类型：</p>
                    <el-select @change="selectChange" v-model="contractType" placeholder="请选择合同类型">
                        <el-option
                        v-for="(item,index) in proData"
                        :key="index"
                        :label="item.contractTypeName"
                        :value="item.contractTypeCode">
                        </el-option>
                    </el-select>
                    <el-button class="search_btn" type="primary" size="small" @click="search()">搜索</el-button>
                    <el-button class="search_btn" size="small" @click="reset()">重置</el-button>
                    <div class="right">
                        <i class="el-icon-setting" @click="getMoreCol"></i>
                    </div>
                    <div class="col_more" v-show="isColMore">
                        <p class="title">编辑列</p>
                        <div class="reset" @click="colReset">重置</div>
                        <el-table
                            ref="multipleTable"
                            :data="tagData"
                            :show-header="false"
                            @selection-change="handleSelectionChangeTag">
                            <el-table-column
                            type="selection"
                            width="55">
                            </el-table-column>
                            <el-table-column>
                            <template slot-scope="scope">{{ scope.row.name }}</template>
                            </el-table-column>
                        
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="chart_box">
                <div class="table-box">
                    <el-table ref="table"  style="width: 100%" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}"  @sort-change="sortChange" @row-click="openDetails">
                        <el-table-column sortable='custom' type="index" label="序号" align="center" width="70"></el-table-column>
                        <el-table-column sortable='custom' prop="executor" label="施工单位" width="240"  :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column sortable='custom' prop="contractCost" align="right" label="合同金额(元)" width="130"></el-table-column>

                        <el-table-column sortable='custom' prop="buildingAreaTotal" align="right" label="总建筑面积(m²)" width="150" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column sortable='custom' prop="cost" align="right" label="单价(元/m²)" width="120"></el-table-column>
                        <el-table-column sortable='custom' prop="contractType" align="left" label="合同类型" width="110" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column prop="contractContent" align="left" label="合同内容"></el-table-column>
                        <el-table-column sortable='custom' v-if="idArr.includes('jsf')"  align="left" prop="company" label="建设方"  width="260"></el-table-column>
                        <el-table-column sortable='custom' v-if="idArr.includes('qy')"  align="left" prop="region" label="区域" width="160"></el-table-column>
                        <!-- <el-table-column v-if="idArr.includes('zksmj')"  prop="region" label="总可售面积" width="160"></el-table-column> -->
                        <el-table-column sortable='custom' v-if="idArr.includes('htrq')"  align="right" prop="contractDate" label="合同日期" width="160"></el-table-column>
                        <el-table-column label="操作" align="center" width="60">
                            <template>
                                <el-button size="mini" type="text">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page-box">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        background
                        layout="prev,pager,next,total,sizes,jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :total="total"
                        :current-page="pageNum">
                    </el-pagination>
                </div>
            </div>
        </el-main>
        
        <el-dialog :title="executor_title" :visible.sync="showDetail" width="90%">
            <detail v-if="showDetail" :proInfo="proInfo"></detail>
        </el-dialog>
    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations, mapState} from "vuex";
import detail from './businessCostDetail'

export default {
    components: {
        detail
    },
    data() {
        return {
            tableData: [],
            loading: true,
            total: 0,
            showDetail: false,
            proInfo: {},
            idArr: [],
            isColMore: false,
            contractType: '',
            pageNum: 1,
            pageSize: 10,
            proData: [],
            tagData: [
                {
                    name:'建设方',
                    id:'jsf'
                },
                {
                    name:'区域',
                    id:'qy'
                },
                // {
                //     name:'总可售面积',
                //     id:'zksmj'
                // },
                // {
                //     name:'合同金额',
                //     id:'htje'
                // },
                {
                    name:'合同日期',
                    id:'htrq'
                },
      
            ],
            model: 'same',
            executor: '',
            executor_title: '',
            creditCode: '',
            company: '',
            field: '',
            order: ''
        }
    },
    created() {
        this.getReport(0,'','');
        this.getContractTypeDict();
    },
    destroyed() {
        this.getReport(1,'','')
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'businessCost',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        search(){
            this.getReport(2,'list','searchBtn');
            this.pageNum = 1;
            this.getTableData();
        },
        //查询talbe数据
        getTableData() {
            this.axios({
                method: "post", url: '/dms/pageContractInfo',
                 
                data: {
                    projectId: getStore('thisProject').id,  //项目id
                    pageNum: this.pageNum,  //页码
                    pageSize: this.pageSize,  //条数
                    contractType: this.contractType,
                    executor: this.executor,
                    field: this.field,
                    order: this.order
                },
            }).then((res) => {
                if(res.data.data.records.length>0){
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                    this.loading = false;
                }else{
                    this.tableData = [];
                    this.total = 0;
                }
                
            }).catch((error) => {
                console.log(error)
            })
        },
        selectChange(val){
        },
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableData();
        },
        //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'list',val);
            this.pageNum = val;
            this.getTableData();
        },
        //查看详情
        openDetails(row) {
            this.getReport(2,'list','detailBtn');
            this.proInfo = row;
            // this.creditCode = row.creditCode;
            // this.company = row.company;
            this.executor_title = row.executor;
            this.showDetail = true;
        },
      
        handleSelectionChangeTag(val){
            this.getReport(2,'list','colEdit');
            let arr = [];
            val.forEach(function(e){  
                arr.push(e.id)
            });
            this.idArr = arr;

        },
        getMoreCol(){
            this.isColMore = !this.isColMore;
        },
        reset(){
            this.getReport(2,'list','resetBtn');
            this.contractType = '';
            this.executor = '';
        },
        colReset(){
            this.getReport(2,'list','colReset');
             this.$refs.multipleTable.clearSelection();   
        },
        sortChange({ prop, order }) {
            this.getReport(2,'list',prop+'Sort');
            this.pageNum = 1;
            this.field = prop;
            if(order == 'ascending'){
                this.order = 'asc'
            }else if(order == 'descending'){
                this.order = 'desc'
            }else{
                this.order = 'asc'
            }
            this.getTableData()
        },
        compare (propertyName, sort) {
            return function (obj1, obj2) {
                var value1 = obj1[propertyName]
                var value2 = obj2[propertyName]
                if (typeof value1 === 'string' && typeof value2 === 'string') {
                    const res = value1.localeCompare(value2, 'zh')
                    return sort === 'ascending' ? res : -res
                } else {
                    if (value1 <= value2) {
                        return sort === 'ascending' ? -1 : 1
                    } else if (value1 > value2) {
                        return sort === 'ascending' ? 1 : -1
                    }
                }
            }
        },
        //获取合同类型
        getContractTypeDict(){
            this.axios({
                method: "post", url: 'dms/getContractTypeDict',
               
            }).then((res) => {
                console.log(res)
              if(res.data.status=='success'){
                  this.proData = res.data.data;
              }
            }).catch((error) => {
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/tbody{
    cursor: pointer;
}
.el-main{
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    padding: 0 !important;
    background-color: #fff;
    .top_title{
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        box-sizing: border-box;
        font-weight: 600;
        color: #333333;
        font-size: 16px;
        border-bottom: 1px solid #EBEBEB;
    }
}
.input_box{
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    position: relative;
    >p{
        float: left;
        line-height: 32px;
    }
    .el-input{
        float: left;
        width: 200px;
        margin-right: 10px;
    }
    .el-select{
        float: left;
    }
    /deep/.el-input__inner{
        width: 200px;
        height: 32px !important;
        line-height: 32px !important;
    }
    .el-button{
        float: left;
        margin-left: 12px;
    }
    .push_chart{
        margin-left: 0 !important;
    }
    .more_search{
        color: #185BCE;
        cursor: pointer;
    }
    .right{
        float: right;
        height: 100%;
        line-height: 32px;
        color: #185BCE;
        >p{
            float: left;
            margin-right: 16px;
            cursor: pointer;
        }
        >i{
            cursor: pointer;
        }
    }
    .col_more{
        width: 214px;
        height: 252px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
        position: absolute;
        top: 50px;
        right: 5px;
        box-sizing: border-box;
        padding: 14px 0;
        z-index: 99;
        .reset{
            position: absolute;
            top: 17px;
            right: 17px;
            width: 28px;
            height: 20px;
            font-size: 14px;
            color: #185BCE;
            cursor: pointer;
        }
        /deep/.el-table td,.el-table th.is-leaf,.el-table--border,.el-table--group {
            border: none;
            cursor: pointer;
        }
        .el-table::before {
            height: 0;
        }
        .title{
            font-size: 16px;
            color: #323233;
            margin-bottom: 15px;
            padding-left: 11px;
            font-weight: 600;
        }
        
    }
}
.module-title {
    font-size: 16px;
    font-weight: bold;
}
.search-box {
    display: flex;
    justify-content: space-between;
}
.chart_box{
    padding: 20px;
}
.table-box {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.page-box {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
/deep/.el-dialog__body{
    padding: 0;
}
</style>